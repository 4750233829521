import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import theme from '../theme';
import DoneIcon from '@mui/icons-material/Done';

const Suggestions2 = () => {
    return (
        <Box sx={{ backgroundColor: '#f6f6f6', pt: '30px', pb: '30px' }}>
            <Box sx={{ width: '90%', mx: 'auto' }}>
                <Typography variant="h2" align='left' mb={4}>
                    Start moving data in minutes
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} lg={6}>
                        <Stack direction='column' spacing={4}>
                            <Typography variant="h3" align='left' color={theme.palette.text.secondary}>
                                For data engineers who want to
                            </Typography>
                            <FeatureBox text="STOP wasting time on complex code and custom queries" />
                            <FeatureBox text="START fully automating pipeline management" />
                            <FeatureBox text="WORK with only with the freshest data" />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Stack direction='column' spacing={4}>
                            <Typography variant="h3" align='left' color={theme.palette.text.secondary}>
                                For business analysts who want to
                            </Typography>
                            <FeatureBox text="STOP waiting for IT to bring data to the warehouse" />
                            <FeatureBox text="START making trusted decisions based on the full picture" />
                            <FeatureBox text="WORK on what matters most — delivering reliable insights" />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

const FeatureBox = ({ text }) => {
    return (
        <Stack direction='row' spacing={1}>
            <DoneIcon style={{ color: theme.palette.primary.main }} />
            <Typography align='left'>{text}</Typography>
        </Stack>
    );
}

export default Suggestions2;
