import { createTheme } from "@mui/material/styles";

const color = {
    primary: "#1976d2",
    tickMark: '#04bcd9',
    textBlack: "#2c2c2c",
    textGray: '#434345',
    primaryLight: "#ffcc20",
    background: ' #f6f6f6',
    btnText: '#ffffff',
    greyBackground: "#6b6767",
};

const palette = {
    borderColor: {
        main: "#08193d"
    },
    headers: {
        main: "#303340"
    },
    gold: {
        main: '#ffb327'
    }
}

const border = {
    xsmall: "1px solid",
    medium: "5px solid",
};

const cursor = {
    pointer: "pointer",
};

const height = {
    xsmall: "0.2rem",
    small: "0.5rem",
    large: "1rem",
    xtlarge: "2rem",
};

const width = {
    xsmall: "0.2rem",
    small: "0.5rem",
    large: "1rem",
    xtlarge: "2rem",
};

const alignment = {
    justifyContent: {
        center: "center",
        left: "left",
        right: "right",
    },
    textAlign: {
        center: "center",
        left: "left",
        right: "right",
    },
};

const font = {
    fontFamily: {
        primary: "'Poppins', sans-serif",
    },
    fontSize: {
        tableHeader: "0.875rem",
        tableBody: "0.75rem",
        cardHeader: "0.875",
    },
};
const components = {
    MuiToolbar: {
        styleOverrides: {
            dense: {
                height: 65,
                minHeight: 32
            }
        }
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                fontSize: '1rem',
                padding: '0px',
                height: '30px',
                '&:hover': {
                    backgroundColor: color.secondary,
                }
            },
        },
    },
}

const typography = {
    allVariants: {
        fontFamily: 'poppings',
        textTransform: 'none',
    },
}

const theme = createTheme({
    color,
    height,
    width,
    alignment,
    border,
    cursor,
    font,
    palette,
    components,
    typography
});

export default theme;