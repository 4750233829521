import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import facebookIcon from "../assets/images/facebook-blue_ycdjwo.svg";
import twitterIcon from "../assets/images/twitter-blue_gn1eca.svg";
import instagramIcon from "../assets/images/instagram-blue_tivqqq.svg";
import linkedInIcon from "../assets/images/linkedin-blue_xjtknz.svg";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const socialIcons = [facebookIcon, twitterIcon, linkedInIcon, instagramIcon];

const menuItems = {
  Products: [
    "Extensibility",
    "Orchestration",
    "Security",
    "Performance",
    "Embedding",
    "Pricing",
    "For Enterprise",
    "Compare ETL Tools",
  ],
  Solutions: ["Sales", "Marketing", "Product Intelligence"],
  Integrations: ["Sources", "Destinations", "Analysis tools"],
  Learn: [
    "Documentation",
    "Community",
    "Blog",
    "Changelog",
    "Resources",
    "Github",
    "Status",
  ],
  // Company: ["About", "Careers", "Contact", "Customers", "Partners"],
};

const categoryColors = {
  Products: "#ff9800",
  Solutions: "#4caf50",
  Integrations: "#2196f3",
  Learn: "#9c27b0",
  Company: "#f44336",
};

const Footer2 = () => {
  return (
    <Box
      id="contact-us"
      sx={{
        backgroundColor: "#333333",
        padding: "40px",
        color: "#ffffff",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {/* <Stack direction="row" spacing={4} mb={4}>
        {socialIcons.map((icon, index) => (
          <img
            key={index}
            src={icon}
            alt={`social-icon-${index}`}
            width="20px"
          />
        ))}
      </Stack> */}
      {/* <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gap: "20px",
          "@media (min-width: 600px)": {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
          "@media (min-width: 960px)": {
            gridTemplateColumns: "repeat(4, 1fr)",
          },
          "@media (min-width: 1200px)": {
            gridTemplateColumns: "repeat(5, 1fr)",
          },
        }}
      > */}
      {/* {Object.entries(menuItems).map(([category, items]) => (
          <Box key={category}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                color: categoryColors[category],
                textAlign: "left",
                mb: 2,
              }}
            >
              {category}
            </Typography>
            <Stack direction="column" spacing={1}>
              {items.map((item, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{ textAlign: "left" }}
                >
                  {item}
                </Typography>
              ))}
            </Stack>
          </Box>
        ))} */}
      <Box width="30%">
        <Typography
          align="center"
          variant="h6"
          color="#ff9800"
          sx={{ mb: 2 }}
          gutterBottom
        >
          Contact Us
        </Typography>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <LocalPhoneIcon />
            <Typography fontWeight="bold" mb={0}>
              Contact Number
            </Typography>
          </Stack>
          <Typography style={{ marginTop: "2px" }} align="center">
            +91 40 3559 3553
          </Typography>
          <Box>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <LocationOnIcon />
              <Typography fontWeight="bold" mb={0}>
                Address
              </Typography>
            </Stack>
            <Typography
              sx={{ marginTop: "2px" }}
              align="center"
              variant="body2"
            >{` Survey No. 68, F. No. 302, Hemadurga Plaza,
              Allwyn X Road, Widia Colony, Hafeezpet, Miyapur, 
              Hyderabad, Telangana 500049`}</Typography>
          </Box>
        </Stack>
      </Box>
      {/* </Box> */}
      {/* <Box sx={{ textAlign: "center", marginTop: "40px" }}>
        <Typography variant="body2">
          &copy; 2005-2024 Talend, Inc. All Rights Reserved
        </Typography>
      </Box> */}
    </Box>
  );
};

export default Footer2;
