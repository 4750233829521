import { Box, Divider, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import theme from '../theme';
const DynamicMenu2 = ({ anchorEl, handleCloseMenu, data }) => {
    return (
        <Box>
            <Menu
                sx={{
                    mt: '33px',
                    "& .MuiMenu-paper": {
                        borderRadius: '0px'
                    }
                }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                PaperProps={{
                    sx: { minWidth: 150 }
                }}
            >
                {data && data.map((item) => (
                    <MenuItem key={item.id} onClick={handleCloseMenu}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '&:hover': {
                                '& .divider': {
                                    backgroundColor: theme.palette.secondary.main, 
                                }
                            }
                        }}
                    >
                        <Divider orientation='vertical' className="divider" sx={{
                            bgcolor: theme.palette.primary.main, 
                            width: '5px',
                            height: '100%'
                        }} />
                        <Typography width='100%' sx={{ fontSize: '0.8rem', paddingTop: '6px' }}>{item.menu_item}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default DynamicMenu2;
