import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import office from "../assets/images/office-building.jpg";
const WhoWeAre = () => {
  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap="20px"
        alignItems="center"
      >
        <Box>
          <Typography variant="h4" gutterBottom>
            Who We Are?
          </Typography>
          <Typography variant="body2">
            Our digital solutions help our customers in acquiring seamless
            business processes with the integration of centralized database, web
            and mobile devices. With flexible onsite/offshore delivery models of
            client engagement, we effectively cut costs and serve customers
            across geographies. With over 30 satisfied clients, we have earned
            high appreciation and acknowledgement for our services.
          </Typography>
        </Box>
        <Box>
          <img src={office} alt="office" width="450px" />
        </Box>
      </Stack>
    </div>
  );
};

export default WhoWeAre;
