import "./App.css";
import {TweakAnalytics} from "./TweakAnalytics"
import { Route, Routes } from "react-router-dom";
import SignInOutContainer from "./signInComponents/SignInOutContainer";
import SignUp from "./signInComponents/signInOutPages/SignUp";


function App() {
  return (
    <div className="App">
      <Routes>
      <Route path="/signinout" element={<SignInOutContainer/>} />
      <Route path="/SignUp" element={<SignUp/>} />
      <Route path="/" element={<TweakAnalytics />} />
      </Routes>
    </div>
  );
}

export default App;
