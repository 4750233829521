import React from "react";
import about from "../assets/images/about-us.jpg";
import { Stack, Typography, Box } from "@mui/material";
const AboutUs = () => {
  return (
    <div id="about">
      <Stack
        direction="row"
        justifyContent="space-between"
        gap="20px"
        alignItems="center"
      >
        <Box>
          <img src={about} alt="office" width="450px" />
        </Box>
        <Box>
          <Typography variant="h4" gutterBottom>
            About Us
          </Typography>

          <Typography variant="body2">
            Since 2022, Tweak Analytics has been defining, designing and
            delivering innovative digital solutions for customers of all sizes.
            We are a web and mobile media services company. Our 100 strong
            technical team have delivered over 300 projects within Custom
            Application Development, Mobile Computing, Enterprise Application
            Integrations, Testing and Application Maintenance.
          </Typography>
        </Box>
      </Stack>
    </div>
  );
};

export default AboutUs;
