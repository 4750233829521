import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import theme from "../theme";
import taLogo from "../assets/images/TA-logo-transparent.png";
import { useState } from "react";
import DynamicMenu2 from "./DynamicMenu2";
import { Drawer } from "@mui/material";

// const pages = [
//   {
//     id: 1,
//     nav_item: "Platform",
//     submenu: [
//       { id: 1, menu_item: "Extensibility" },
//       { id: 2, menu_item: "Orchestration" },
//       { id: 3, menu_item: "Security & Compliance" },
//       { id: 4, menu_item: "Performance & Reliability" },
//       { id: 5, menu_item: "Embedding" },
//       { id: 6, menu_item: "Transformation & Quality" },
//       { id: 7, menu_item: "For Enterprise" },
//     ],
//   },
//   {
//     id: 2,
//     nav_item: "Solutions",
//     submenu: [
//       { id: 1, menu_item: "Sales" },
//       { id: 2, menu_item: "Marketing" },
//       { id: 3, menu_item: "Product Intelligence" },
//     ],
//   },
//   {
//     id: 3,
//     nav_item: "Integrations",
//     submenu: [
//       { id: 1, menu_item: "Sources" },
//       { id: 1, menu_item: "Destination and Warehouses" },
//       { id: 3, menu_item: "Analysis Tools" },
//     ],
//   },
//   { id: 4, nav_item: "Pricing" },
//   { id: 5, nav_item: "Customers" },
//   { id: 6, nav_item: "Contact Us" },
//   { id: 7, nav_item: "Sign in" },
// ];

function HomeNavbar2() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // const handleOpenSubMenu = (event, page) => {
  //   if (
  //     page.submenu &&
  //     page.nav_item !== "Pricing" &&
  //     page.nav_item !== "Customers" &&
  //     page.nav_item !== "Documentation" &&
  //     page.nav_item !== "Sign in"
  //   ) {
  //     setAnchorEl(event.currentTarget);
  //     //   console.log(event.currentTarget);
  //     setData(page.submenu);
  //   } else {
  //     setData(null);
  //   }
  // };
  const tabs = [
    { id: 1, tab: "Development", link: "intro" },
    { id: 1, tab: "What We Do", link: "what-we-do" },
    { id: 1, tab: "Technology", link: "tech" },
    { id: 1, tab: "About Us", link: "about" },
    { id: 1, tab: "Contact Us", link: "contact-us" },
  ];
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: "#00796B",
          marginBottom: "0px",
          textTransform: "none",
          boxShadow: "none",
        }}
      >
        <Container maxWidth="xl" sx={{ marginTop: "10px" }}>
          <Toolbar variant="dense">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box>
                <a href="#intro">
                  <img
                    src={taLogo}
                    alt="log"
                    style={{
                      cursor: "pointer",
                      marginBottom: "6px",
                      marginLeft: "-30px",
                      height: "50px",
                      width: "180px",
                    }}
                  ></img>
                </a>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "65%",
                  justifyContent: "space-between",
                }}
              >
                {tabs.map((ele) => {
                  return (
                    <a href={`#${ele.link}`} style={{ textDecoration: "none" }}>
                      <Button
                        variant="text"
                        sx={{
                          // borderColor: theme.palette.common.white,
                          color: theme.palette.common.white,
                          // backgroundColor: "#8f8fe9",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                          display: { xs: "none", sm: "flex" },
                        }}
                      >
                        {ele.tab}
                      </Button>
                    </a>
                  );
                })}
              </Box>
            </Box>
            <DynamicMenu2
              anchorEl={anchorEl}
              handleCloseMenu={handleCloseMenu}
              data={data}
            />
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
      ></Drawer>
    </>
  );
}

export default HomeNavbar2;
