import React from "react";
import HomeNavbar2 from "../components2/HomeNavbar2";
import IntroComponent2 from "../components2/IntroComponent2";
import Businesses2 from "../components2/Businesses2";
import CloudComponent2 from "../components2/CloudComponent2";
import DataSources2 from "../components2/DataSources2";
import Suggestions2 from "../components2/Suggestions2";
import Footer2 from "../components2/Footer2";
import { Box, Divider } from "@mui/material";
import theme from "../theme";
import WhoWeAre from "../components2/WhoWeAre";
import WebDevelopment from "../components2/WebDevelopment";
import Technology from "../components2/Technology";
import AboutUs from "../components2/AboutUs";
import WhatWeDo from "../components2/WhatWeDo";
export const TweakAnalytics = () => {
  return (
    <div className="App">
      <HomeNavbar2 />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "50px" }}>
        <IntroComponent2 />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "50px",
            width: "95%",
            margin: "auto",
          }}
        >
          <WhoWeAre />
          <WebDevelopment />
          <Divider
            sx={{
              height: "0.5px",
              backgroundColor: theme.color.greyBackground,
            }}
          />
          <WhatWeDo />
          <Divider
            sx={{
              height: "0.5px",
              backgroundColor: theme.color.greyBackground,
            }}
          />
          <Technology />
          <Divider
            sx={{
              height: "0.5px",
              backgroundColor: theme.color.greyBackground,
            }}
          />
          <Businesses2 />
          <Divider
            sx={{
              height: "0.5px",
              backgroundColor: theme.color.greyBackground,
            }}
          />
          <CloudComponent2 />
          <Divider
            sx={{
              height: "0.5px",
              backgroundColor: theme.color.greyBackground,
            }}
          />
          <DataSources2 />
          <Suggestions2 />
          <AboutUs />
        </Box>
        <Footer2 />
      </Box>
    </div>
  );
};
