import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import theme from "../theme";
import sources from "../assets/images/sources.jpg";

const DataSources2 = () => {
  return (
    <Box sx={{ width: "90%", mx: "auto" }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            fontSize="2.25rem"
            color={theme.palette.text.secondary}
            align="center"
          >
            Connect to 140+ of your favorite data sources
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <img src={sources} alt="sources" style={{ width: "100%" }} />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          {/* <Button variant='contained' sx={{
                        p: '16px 32px',
                        fontWeight: 'bold',
                        fontSize: '1.1rem',
                        textTransform: 'none',
                    }}>See all sources</Button> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataSources2;
