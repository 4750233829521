import React from "react";
import bot2 from "../assets/images/bot2.jpg";
import { Stack, Typography, Box } from "@mui/material";
import projectDev from "../assets/images/project-development.jpg";
import consulting from "../assets/images/consulting.png";
const WhatWeDo = () => {
  return (
    <div id="what-we-do">
      <Typography variant="h4" gutterBottom mb={4}>
        What We Do
      </Typography>
      <Stack spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          gap="20px"
          alignItems="center"
        >
          <Box>
            <Typography variant="h5" gutterBottom>
              Build. Operate. Transfer.
            </Typography>
            <Typography variant="body2">
              Tweak Analytics offers comprehensive support in establishing your
              Center of Excellence (COE), handling every aspect of the endeavor
              from inception. We begin by facilitating company
              formation/registration and proceed to identify suitable physical
              locations, as well as set up necessary networks and
              infrastructure. Moreover, Avesta takes charge of recruiting the
              complete technology, accounting, finance, and human resource
              management teams. Leveraging our proprietary
              build-operate-transfer model, we provide the COE build-out as a
              service, ensuring a streamlined process with a predefined price
              and timeline.
            </Typography>
          </Box>
          <Box>
            <img src={bot2} alt="office" width="450px" />
          </Box>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          gap="20px"
          alignItems="center"
        >
          <Box>
            <img src={projectDev} alt="office" width="450px" />
          </Box>
          <Box>
            <Typography variant="h5" gutterBottom>
              Project Development
            </Typography>
            <Typography variant="body2">
              In our Project Development section, delve into our comprehensive
              approach to bringing your vision to life. From conceptualization
              to execution, we specialize in guiding projects through every
              stage of development, ensuring seamless implementation and optimal
              outcomes. Our services encompass project planning, resource
              allocation, timeline management, and risk assessment, all tailored
              to meet the unique needs of your project. Backed by a team of
              seasoned professionals with a diverse skill set and a passion for
              innovation, we leverage the latest technologies and industry best
              practices to deliver results that exceed expectations. Explore our
              Project Development services today and unlock the potential of
              your next venture.
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          gap="20px"
          alignItems="center"
        >
          <Box>
            <Typography variant="h5" gutterBottom>
              Consulting
            </Typography>
            <Typography variant="body2">
              In our Consulting section, discover tailored solutions crafted by
              industry experts to drive your business forward. Our consultancy
              services encompass strategic planning, market analysis, project
              management, and cutting-edge technological solutions. Backed by a
              proven track record of success, our team brings a wealth of
              experience, certifications, and industry accolades to the table.
              With a collaborative approach and a commitment to innovation, we
              empower clients to achieve their goals efficiently and
              effectively. Explore our consultancy services today and take the
              first step toward realizing your business's full potential."
            </Typography>
          </Box>
          <Box>
            <img src={consulting} alt="office" width="450px" />
          </Box>
        </Stack>
      </Stack>
    </div>
  );
};

export default WhatWeDo;
