import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import logo1 from "../assets/images/logo-envoy_r2kp5v.svg";
import logo2 from "../assets/images/logo-customer-prezzee-gray_i3lkoa.svg";
import logo3 from "../assets/images/logo-simba-gray_gnypmc.svg";
import logo4 from "../assets/images/logo-customer-crossbeam-gray_maqc7c.svg";
import logo5 from "../assets/images/logo-customer-headspace-gray_wgiykj.svg";
import logo6 from "../assets/images/logo-indiegogo_o7jt3q.svg";
import theme from "../theme";

const Businesses2 = () => {
  return (
    <Box id="Customers">
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          sx={{ mb: 8, color: theme.palette.text.secondary }}
        >
          Thousands of businesses rely on Tweak Analytics
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={6} sm={4} md={2}>
            <img
              src={logo1}
              alt="logo1"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <img
              src={logo2}
              alt="logo2"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <img
              src={logo3}
              alt="logo3"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <img
              src={logo4}
              alt="logo4"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <img
              src={logo5}
              alt="logo5"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <img
              src={logo6}
              alt="logo6"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Businesses2;
