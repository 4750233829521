import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import hexa1 from "../assets/images/hexagon1.svg";
import hexa2 from "../assets/images/hexagon2.svg";
import hexa3 from "../assets/images/hexagon3.svg";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import theme from "../theme";

const CloudComponent2 = () => {
  return (
    <Container maxWidth="lg">
      <Stack spacing={8} alignItems="center">
        <Typography
          align="center"
          variant="h3"
          color={theme.palette.text.primary}
        >
          Zero-maintenance cloud data pipelines in minutes
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <Stack spacing={2} alignItems="center">
              <img src={hexa1} alt="hexa1" width="50px"></img>
              <Typography
                align="center"
                variant="h5"
                fontWeight={600}
                color={theme.palette.text.secondary}
              >
                Save engineering time
              </Typography>
              <Typography align="center" variant="body1">
                Extract data from 140+ popular sources to your warehouse or
                database in minutes — no coding required.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack spacing={2} alignItems="center">
              <img src={hexa2} alt="hexa2" width="50px"></img>
              <Typography
                align="center"
                variant="h5"
                fontWeight={600}
                color={theme.palette.text.secondary}
              >
                Create a single source of truth
              </Typography>
              <Typography align="center" variant="body1">
                Centralize siloed business data into your cloud data warehouse
                for fresh, analysis-ready data.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack spacing={2} alignItems="center">
              <img src={hexa3} alt="hexa3" width="50px"></img>
              <Typography
                align="center"
                variant="h5"
                fontWeight={600}
                color={theme.palette.text.secondary}
              >
                Avoid ongoing maintenance
              </Typography>
              <Typography align="center" variant="body1">
                Tweak Analytics pipelines automatically and continuously update,
                freeing you to focus on insights, not IT.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        {/* <Button variant="contained" sx={{ textTransform: 'none' }} endIcon={<ArrowRightAltIcon />}>Try it free</Button> */}
      </Stack>
    </Container>
  );
};

export default CloudComponent2;
