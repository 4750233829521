import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardMedia,
  Typography,
  Button,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import RxDotfilled from "@mui/icons-material/RadioButtonChecked";
import CustomerSoftwares from "../assets/videos/CustomerSoftwares.mp4";
import BotVideo from "../assets/videos/Bot.mp4";
import ProductDevelopment from "../assets/videos/ProductDevelopment.mp4";

const useStyles = makeStyles((theme) => ({
  main: {
    position: "relative",
    height: "100vh",
    overflow: "hidden",
    zIndex: 0,
  },
  card: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "80vh",
    [theme.breakpoints.down("sm")]: {
      minHeight: "60vh",
    },
  },
  video: {
    width: "100%",
    height: "100vh",
    objectFit: "cover",
    position: "relative",
    zIndex: 0,
    [theme.breakpoints.down("sm")]: {
      height: "60vh",
    },
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "#db6d6df5",
    zIndex: 1,
    padding: "0 20px",
    [theme.breakpoints.down("sm")]: {
      top: "40%",
      left: "30%",
      transform: "translate(-43% -55%)",
    },
  },
  bottomNav: {
    position: "absolute",
    bottom: "10%",
    paddingLeft: "40%",
    zIndex: 2,
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      bottom: "40%",
      paddingLeft: "20%",
    },
  },
  actionButton: {
    color: "white",
  },
}));

const videoData = [
  {
    video: ProductDevelopment,
    title: "Product Development",
    description: "This is the Product Development description.",
  },
  {
    video: CustomerSoftwares,
    title: "Customer Softwares",
    description: "This is the Customer Softwares description.",
  },
];

const IntroComponent2 = () => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);

  const handleChangeIndex = (index) => {
    setIndex(index);
  };

  return (
    <div className={classes.main} id="intro">
      <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
        {videoData.map((item, i) => (
          <Card key={i} className={classes.card}>
            <CardMedia
              component="video"
              autoPlay
              loop
              muted
              className={classes.video}
              src={item.video}
            />
            {/* <div className={classes.content}>
              <Typography variant="h4" component="h1">
                {item.title}
              </Typography>
              <Typography variant="body1">{item.description}</Typography> */}
            {/* <Button variant="contained">Try it for free</Button> */}
            {/* </div> */}
          </Card>
        ))}
      </SwipeableViews>
      <BottomNavigation
        value={index}
        onChange={(event, newValue) => {
          setIndex(newValue);
        }}
        showLabels
        className={classes.bottomNav}
      >
        {videoData.map((item, i) => (
          <BottomNavigationAction
            key={i}
            className={classes.actionButton}
            icon={<RxDotfilled />}
          />
        ))}
      </BottomNavigation>
    </div>
  );
};

export default IntroComponent2;
