import { Box, Card, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import tech from "../assets/images/technology.jpg";
import android from "../assets/images/android.png";
import ios from "../assets/images/ios.png";
import hybrid from "../assets/images/hybrid.png";
import html from "../assets/images/html.png";
import javaScript from "../assets/images/javascript.png";
import bootStrap from "../assets/images/bootstrap.png";
import jQuery from "../assets/images/jquery.png";
import dotnet from "../assets/images/dotnet.png";
import java from "../assets/images/java.png";
import php from "../assets/images/php.png";
import wordpress from "../assets/images/wordpress.png";
const mobileTech = [
  { id: 1, img: android },
  { id: 2, img: ios },
  { id: 3, img: hybrid },
];
const DesignTech = [
  { id: 1, img: html },
  { id: 2, img: javaScript },
  { id: 3, img: bootStrap },
  { id: 4, img: jQuery },
];
const WebDevTech = [
  { id: 1, img: dotnet },
  { id: 2, img: java },
  { id: 3, img: php },
  { id: 4, img: wordpress },
];

const Technology = () => {
  return (
    <div id="tech">
      <Stack
        direction="row"
        justifyContent="space-between"
        gap="20px"
        alignItems="center"
      >
        <Box width="60%">
          <Stack spacing={2}>
            <Box>
              <Typography
                align="left"
                gutterBottom
                fontWeight="bold"
                mb={2}
                sx={{ color: "#512DA8" }}
              >
                Mobile Development
              </Typography>
              <Box sx={{ display: "flex", gap: "20px" }}>
                {mobileTech.map((ele) => {
                  return (
                    <Paper
                      key={ele.id}
                      sx={{ padding: "5px", border: "1px solid #00796B" }}
                    >
                      <img src={ele.img} alt="android" width="100px" />
                    </Paper>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Typography
                align="left"
                fontWeight="bold"
                gutterBottom
                mb={2}
                sx={{ color: "#512DA8" }}
              >
                Design
              </Typography>
              <Box sx={{ display: "flex", gap: "20px" }}>
                {DesignTech.map((ele) => {
                  return (
                    <Paper key={ele.id} sx={{ padding: "5px" }}>
                      <img src={ele.img} alt="android" width="100px" />
                    </Paper>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Typography
                align="left"
                fontWeight="bold"
                gutterBottom
                mb={2}
                sx={{ color: "#512DA8" }}
              >
                Web Development
              </Typography>
              <Box sx={{ display: "flex", gap: "20px" }}>
                {WebDevTech.map((ele) => {
                  return (
                    <Paper
                      key={ele.id}
                      sx={{ padding: "5px", border: "1px solid #00796B" }}
                    >
                      <img
                        src={ele.img}
                        alt="android"
                        width="100px"
                        style={{ margin: "auto" }}
                      />
                    </Paper>
                  );
                })}
              </Box>
            </Box>
          </Stack>
        </Box>
        <Box width="40%">
          <Typography variant="h4" gutterBottom>
            Technology
          </Typography>
          <Typography variant="body2">
            Our commitment to excellence shines through our expertise in a
            diverse array of cutting-edge technologies and methodologies.
            Specializing in cross-platform development, we leverage innovative
            solutions and Agile methodologies to ensure seamless compatibility
            and timely delivery. With deep knowledge in cloud integration and a
            strong focus on security and compliance, we empower businesses with
            scalable, flexible, and secure solutions. Our collaborative
            approach, data-driven insights, and user-centric design principles
            drive us to continuously improve and deliver exceptional experiences
            that consistently exceed client expectations.
          </Typography>
        </Box>
      </Stack>
    </div>
  );
};

export default Technology;
