import React, { useState } from "react";
import Select from "react-select";
import Flag from "react-country-flag";
import {
  Grid,
  Paper,
  Avatar,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const SignUp = () => {
  const paperStyle = { padding: 20, width: 300, margin: "0 auto" };
  const headerStyle = { margin: 0 };
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const marginTop = { marginTop: 5 };
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    gender: "",
    phoneNumber: "+91", 
    password: "",
    confirmPassword: "",
    termsAccepted: false,
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    gender: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    termsAccepted: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Resetting the error message for the field being changed
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
    // Resetting the error message for the checkbox being changed
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    // Validation logic
    if (!formData.name) {
      errors.name = "Name is required";
    }
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }
    if (!formData.gender) {
      errors.gender = "Gender is required";
    }
    if (!formData.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    } else {
      // Validate phone number length based on country code
      const countryCode = formData.phoneNumber.substring(0, 3); // Extract country code from phone number
      let expectedLength;
      switch (countryCode) {
        case "+91": // India
          expectedLength = 13; // +91 followed by 10 digits
          break;
        case "+1": // United States
          expectedLength = 15; // +1 followed by 10 digits
          break;
        // Add cases for other country codes as needed
        default:
          expectedLength = 0; // Unknown country code, set to 0 to skip length validation
          break;
      }
      if (expectedLength !== 0 && formData.phoneNumber.length !== expectedLength) {
        errors.phoneNumber = `Phone number should be ${expectedLength} digits for ${countryCode}`;
      }
    }
    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    }
    if (!formData.confirmPassword) {
      errors.confirmPassword = "Confirm password is required";
    } else if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
    if (!formData.termsAccepted) {
      errors.termsAccepted = "Please accept the terms and conditions";
    }

    if (Object.keys(errors).length === 0) {
      // All fields are filled, you can proceed with form submission
      console.log("Form submitted:", formData);
      // You can perform further actions like API calls here
    } else {
      setFormErrors(errors);
    }
  };

  const handleBackToHome = () => {
    navigate("/");
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };


  const countryCodes = [
    { value: "+91", label: <Flag countryCode="IN" svg /> }, // India
    { value: "+1", label: <Flag countryCode="US" svg /> }, // United States
    { value: "+44", label: <Flag countryCode="GB" svg /> }, // United Kingdom
    { value: "+49", label: <Flag countryCode="DE" svg /> }, // Germany
    { value: "+86", label: <Flag countryCode="CN" svg /> }, // China
    { value: "+81", label: <Flag countryCode="JP" svg /> }, // Japan
    { value: "+7", label: <Flag countryCode="RU" svg /> }, // Russia
    { value: "+55", label: <Flag countryCode="BR" svg /> }, // Brazil
    { value: "+62", label: <Flag countryCode="ID" svg /> }, // Indonesia
    { value: "+49", label: <Flag countryCode="DE" svg /> }, // Germany
    { value: "+33", label: <Flag countryCode="FR" svg /> }, // France
    { value: "+39", label: <Flag countryCode="IT" svg /> }, // Italy
    { value: "+81", label: <Flag countryCode="JP" svg /> }, // Japan
    { value: "+82", label: <Flag countryCode="KR" svg /> }, // South Korea
    { value: "+234", label: <Flag countryCode="NG" svg /> }, // Nigeria
    { value: "+52", label: <Flag countryCode="MX" svg /> }, // Mexico
    { value: "+63", label: <Flag countryCode="PH" svg /> }, // Philippines
    { value: "+27", label: <Flag countryCode="ZA" svg /> }, // South Africa
    { value: "+34", label: <Flag countryCode="ES" svg /> }, // Spain
    { value: "+46", label: <Flag countryCode="SE" svg /> }, // Sweden
  ];

  return (
    <Grid>
      <Paper style={paperStyle}>
        <Grid align="center">
          <Avatar style={avatarStyle}>
            <AddCircleOutlineOutlinedIcon />
          </Avatar>
          <h2 style={headerStyle}>Sign Up</h2>
          <Typography variant="caption" gutterBottom>
            Please fill this form to create an account !
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Name"
            placeholder="Enter your name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            error={!!formErrors.name}
            helperText={formErrors.name}
          />
          <TextField
            fullWidth
            label="Email"
            placeholder="Enter your email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            error={!!formErrors.email}
            helperText={formErrors.email}
          />
          <FormControl
            component="fieldset"
            style={marginTop}
            error={!!formErrors.gender}
          >
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender"
              style={{ display: "initial" }}
              value={formData.gender}
              onChange={handleInputChange}
            >
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
            </RadioGroup>
            {formErrors.gender && (
              <Typography variant="caption" color="error">
                {formErrors.gender}
              </Typography>
            )}
          </FormControl>
          {/* Phone number */}
          <div style={marginTop}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Select
                  options={countryCodes}
                  defaultValue={countryCodes.find(
                    (option) => option.value === formData.phoneNumber
                  )}
                  onChange={(selectedOption) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      phoneNumber: selectedOption.value,
                    }))
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  error={!!formErrors.phoneNumber}
                  helperText={formErrors.phoneNumber}
                />
              </Grid>
            </Grid>
          </div>
          <TextField
          fullWidth
          label="Password"
          placeholder="Enter your password"
          type={showPassword ? "text" : "password"}
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          error={!!formErrors.password}
          helperText={formErrors.password}
          InputProps={{
            endAdornment: (
              <IconButton onClick={togglePasswordVisibility}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
        />
          <TextField
            fullWidth
            label="Confirm Password"
            placeholder="Confirm your password"
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            error={!!formErrors.confirmPassword}
            helperText={formErrors.confirmPassword}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleCheckboxChange}
              />
            }
            label="I accept the terms and conditions."
            error={!!formErrors.termsAccepted}
          />
          {formErrors.termsAccepted && (
            <Typography variant="caption" color="error">
              {formErrors.termsAccepted}
            </Typography>
          )}
          <Button type="submit" variant="contained" color="primary">
            Sign up
          </Button>
        </form>
        <Typography>
          <Link to="/" onClick={handleBackToHome}>
            Back to Home
          </Link>{" "}
        </Typography>
      </Paper>
    </Grid>
  );
};

export default SignUp;
